import React from "react";
import { Container, Row, Col } from "reactstrap";

import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardImg from "reactstrap/lib/CardImg";
import CardSubtitle from "reactstrap/lib/CardSubtitle";

import CardTitle from "reactstrap/lib/CardTitle";

function TextGrid() {
  return (
    <>
      <div
        style={{
          background: "#2296e3",
        }}
        className='section'
        data-parallax={true}
      >
        <Container className='mt-5'>
          <Row>
            {recent.map((c) => (
              <Col lg={4}>
                <a
                  href={c.link}
                  target='_blank'
                  without
                  rel='noopener noreferrer'
                >
                  <Card style={{ minHeight: '370px', marginBottom: '30px' }}>
                    <CardImg
                      alt={c.title}
                      src={require(`assets/editions/${c.image}`)}
                      top
                      width='100%'
                    />
                    <CardBody>
                      <CardTitle tag='h2'>{c.title}</CardTitle>
                      <CardSubtitle className='mt-1 mb-2 text-muted' tag='h3'>
                        {c.date}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const recent = [
  /* {
    title: "Artelligence Forum 2022",
    date: "August 2022",
    description: "",
    link: "https://artelligenceforum.com/",
    image: "artelligence.png",
  }, */

  {
    title: "Future of Insurance",
    date: "January 2021",
    image: "foic.png",
    link: "https://futureofinsurancecongress.com/",
  },

  {
    title: "KT Desert Drive",
    date: "November 2021",
    image: "desert.png",
    link: "https://ktdesertdrive.com/",
  },

  {
    title: "Remote Workforce Summit",
    date: "March 2021",
    image: "remote.jpg",
    link: "https://remoteworkforcesummit.com/",
  },

  {
    title: "Global Investment Forum",
    date: "June 2021",
    image: "gif.png",
    link: "https://gif.khaleejtimesevents.com/",
  },

  {
    title: "DIGIPAY 2021",
    date: "July 2021",
    image: "digipay.png",
    link: "https://digipaysummit.com/",
  },

  {
    title: "UniExpo",
    date: "October 2021",
    description: "",
    link: "https://www.ktuniexpo.com/",
    image: "uniexpo.png",
  },

  {
    title: "IRES 2021",
    date: "December 2021",
    image: "ires.png",
    link: "https://indianrealestateshow.com/",
  },

  /* {
    title: "FutureSec 2021",
    date: "November 2021",
    image: "futuresec.png",
    link: "https://futuresecsummit.com/",
  }, */

  // {
  //   title: "India UAE Israel Summit",
  //   date: "19th October 2021",
  //   image: "uniexpo.png",
  // },

  // {
  //   title: "Karnataka Expo 2020 Summit",
  //   date: "17th October 2021",
  //   image: "uniexpo.png",
  // },
];

export default TextGrid;
