import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardImg from "reactstrap/lib/CardImg";

import CardTitle from "reactstrap/lib/CardTitle";

function TextGrid() {
  return (
    <>
      <div
        style={{
          background: "#2296e3",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-white mb-5 text-uppercase">
                <b>Coming Up</b>
              </h1>
            </Col>
          </Row>

          <Row>
            {comingup.map((c) => (
              <Col lg={4}>
                <a href={c.link} target="_blank" without rel="noopener noreferrer">
                  <Card style={{ minHeight: '330px', marginBottom: '30px' }}>
                    <CardImg
                      alt={c.title}
                      src={require(`assets/editions/${c.image}`)}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <CardTitle tag="h3">
                        <center>{c.title}</center>
                      </CardTitle>
                      {/* 
                      <CardSubtitle className='mt-1 mb-2 text-muted text-center' tag='h3'>
                        <span dangerouslySetInnerHTML={{ __html: c.date }}></span>
                      </CardSubtitle>
                      */}
                      {/* <CardText className='text-400'>{c.description}</CardText> */}
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <Col lg={12}>
              <h1 className="text-white mb-5 text-uppercase">
                <b>Recent Events</b>
              </h1>
            </Col>
          </Row>
          <Row>
            {recent.map((c) => (
              <Col lg={4}>
                <a href={c.link} target="_blank" without rel="noopener noreferrer">
                  <Card style={{ minHeight: '330px', marginBottom: '30px' }}>
                    <CardImg
                      alt={c.title}
                      src={require(`assets/editions/${c.image}`)}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <CardTitle tag="h3">
                        <center>{c.title}</center>
                      </CardTitle>
                      {/* <CardSubtitle className='mt-1 mb-2 text-muted' tag='h3'>
                        {c.date}
                      </CardSubtitle> */}
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
            <Col className="d-none" lg={12}>
              <Button className="btn" color="primary" outline size="md" href="/recent">
                Load More
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export const comingup = [
  {
    title: "UniExpo",
    date: "18<sup>th</sup> & 19<sup>th</sup> September, 2024",
    description: "",
    link: "https://www.ktuniexpo.com/",
    image: "uniexpo.png",
  },
  {
    title: "We The Women",
    date: "16<sup>th</sup> October, 2024",
    image: "wtw-new.png",
    link: "https://wtw.khaleejtimesevents.com/",
  },
  {
    title: "FutureSec'24",
    date: "30<sup>th</sup> October, 2024",
    description: "",
    link: "https://futuresec.khaleejtimesevents.com/",
    image: "futuresec-logo.png",
  },
  {
    title: "BIT'24 Dubai, UAE",
    date: "12<sup>th</sup>, 13<sup>th</sup> & 14<sup>th</sup> November, 2024",
    image: "bitDubai2024.png",
    link: "https://bit.khaleejtimesevents.com/dubai",
  },
  {
    title: "Khaleej Times Awards",
    date: "15<sup>th</sup> November, 2024",
    description: "",
    link: "/khaleejtimes-awards",
    image: "image 114.png",
  },
  {
    title: "Future of Healthcare",
    date: "27<sup>th</sup> November, 2024",
    image: "dhf.png",
    link: "https://dhf.khaleejtimesevents.com/",
  },
  {
    title: "KT Desert Drive 2024",
    date: "14<sup>th</sup> December, 2024",
    description:
      "The love for the desert is something that is in common for the UAE citizens and expats alike. The KT Desert Drive experience is a must-do in Dubai, it is NOT a desert safari where you sit as a passenger at the mercy of a driver.",
    link: "https://ktdesertdrive.com/",
    image: "desert4.png",
  },
  {
    title: "BIT'25 Riyadh, Saudi Arabia",
    date: "February, 2025",
    image: "bit24riyadh.png",
    link: "http://bit.khaleejtimesevents.com/riyadh",
  },
];

export const recent = [
  {
    title: "Newgen",
    date: "5<sup>th</sup> September, 2024",
    image: "newgen.jpg",
    link: "/newgen-roundtable",
  },
  {
    title: "The Journey to NET ZERO",
    date: "June 2024",
    description: "",
    link: "https://ktnetzero.khaleejtimesevents.com/",
    image: "ktnetzero.webp",
  },
  {
    title: "CX Evolve",
    date: "February 2024",
    description: "",
    link: "/cx-evolve",
    image: "cxevolve.webp",
  },
  {
    title: "Corporate Tax Decoded",
    date: "31st July 2024",
    description: "",
    link: "https://newagefinanceexpo.com/corporate-tax-decode",
    image: "corporate-tax-decode.jpg",
  },
  {
    title: "Banking Innovation & Technology Summit, Doha Qatar",
    date: "November 2023",
    image: "bit.png",
    link: "http://bit.khaleejtimesevents.com/qatar",
  },
  {
    title: "New Age Finance & Accounting Summit",
    date: "21st June 2023",
    image: "nafa.png",
    link: "https://newagefinanceexpo.com",
  },
  {
    title: "An Exclusive Iftaar Evening",
    date: "March 2023",
    description: "",
    link: "/branch-iftaar",
    image: "iftaar.png",
  },
  {
    title: "Banking Innovation & Technology Summit, Dubai UAE",
    date: "November 2023",
    image: "bit.png",
    link: "http://bit.khaleejtimesevents.com",
  },
  {
    title: "Tribe CMO Connect",
    date: "April 2023",
    description: "",
    link: "http://tribe.khaleejtimesevents.com",
    image: "tribe.png",
  },
  {
    title: "The Future of Financial Services",
    date: "October 2022",
    description: "",
    link: "/cxo-executive-summit",
    image: "cxo-summit.png",
  },
  {
    title: "India Real Estate Show 2023",
    date: "May 2023",
    description:
      "IRES 2023 will once again assemble some of India's biggest real estate players and provide an opportunity to the UAE's non-resident Indians (NRIs) and other foreign investors in the country to cash in on lucrative onsite deals and financing options.",
    link: "https://indianrealestateshow.com/",
    image: "iresmain.png",
  },

  {
    title: "Recomposing Banking",
    date: "September 2023",
    image: "infosys.png",
    link: "/recomposing-banking",
  },

  {
    title: "i3 Summit",
    date: "May 2021",
    image: "i3.png",
    link: "https://i3.khaleejtimesevents.com/",
  },
  {
    title: "Future of Insurance",
    date: "February 2022",
    image: "foic.png",
    link: "https://futureofinsurancecongress.com/",
  },
  {
    title: "Middle East - Women Board of Directors",
    date: "April 2022",
    description: "",
    link: "/women-directors",
    image: "womenMain.jpg",
  },
  {
    title: "Meta Vision 2022",
    date: "22 September 2022",
    description: "",
    link: "/meta-vision2022",
    image: "metalogo.png",
  },
  {
    title: "UAE-INDIA Investment Forum 2022",
    date: "March 2022",
    description: "",
    link: "/uae-india-investment-forum",
    image: "uaeii.png",
  },
];

export default TextGrid;
