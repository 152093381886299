import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className='section'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h1 className='text-main text-uppercase mb-4'>
                <img
                  src={require(`assets/images/logo/khaleej-new.png`)}
                  className=""
                  width="100%"
                  style={{ maxWidth: "300px" }}
                  alt="logo"
                />
                {/* <b>About Khaleej Times Events</b> */}
              </h1>
              <h2 className='text-dark text-400 mb-0'>
                KT Events, a division of Khaleej Times, is a leading organizer of high-profile conferences, summits, and exhibitions across the Middle East. We are dedicated to creating impactful and memorable experiences that connect industry leaders, innovators, and influencers.
                {/* <br />
                <br />
                <a
                  href='https://drive.google.com/uc?id=1dl24V9XpiYYeK4mgGqUvuc1mCTghS2zC&export=download'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-400'
                >
                  <b>Download the 2022 events calendar</b>
                </a> */}
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
