import React from "react";

import { Container, Row, Col, Modal } from "reactstrap";
import SpeakerModal from "componentsCX/SpeakerModal.js";
import Agenda from "componentsCX/Agenda";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import "./cxevolve.css";

export const chairmans = [];
function SpeakerGrid({ speakers, CurrentSpeakers }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: "p-10",
  };

  const settingsdesktop = {
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 1786,
    slidesToShow: 6,
    slidesToScroll: 3,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Container className="mb-3">
          <Row>
            <Col xs={12} className="px-0">
              <div className="px-3 pt-3 rounded-circle">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  width="100%"
                  className="rounded-circle"
                  alt="..."
                  style={{
                    borderRadius: 0,
                    maxWidth: "450px",
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="text-center">
              <h2 className="text-dark text-700-cx  mt-2">{speaker.name}</h2>

              <h4 className="text-400 text-muted m-0">{speaker.title}</h4>
              <h4 className="text-dark text-900-cx  m-0 d-block px-0">{speaker.company}</h4>
            </Col>
          </Row>
        </Container>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>SPEAKERS 2024</b>
              </h1>
            </Col>
          </Row>
          <Row className="mb-4 justify-content-center d-non1e">
            {speakers2024
              .filter((s) => !s.isOnlyJudge)
              .map((chairman) => (
                <Col lg={3} className="text-center mb-4">
                  {/* href="/" onClick={(e) => updateModal(e, chairman)} */}
                  <div
                    style={{
                      border: '1px solid #f5f5f5',
                      padding: '10px',
                      minHeight: '405px',
                    }}
                  >
                    <img
                      src={`/speakers/cx-evolve/2024/${chairman.image}`}
                      width="100%"
                      className="rounded-circle"
                      alt="..."
                      style={{
                        borderRadius: '50%',
                        height: '255px',
                        border: '1px solid #f5f5f5',
                        width: '380px',
                        maxWidth: '100%',
                        objectPosition: 'top',
                        objectFit: 'cover',
                      }}
                    />
                    <div className="text-center">
                      <h2 className="text-dark text-700-cx  mt-2">{chairman.name}</h2>
                      <h4 className="text-400 text-muted m-0">{chairman.title}</h4>
                      <h4 className="text-dark text-900-cx  m-0 d-block px-0">{chairman.company}</h4>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className="mb-4">
              <h1
                className="text-center section-title-cx pt-4"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>AGENDA</b>
              </h1>
            </Col>
          </Row>
        </Container>
        <Agenda />
        <Container>
          <Row>
            <Col lg={12} className="mt-5">
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>PAST SPEAKERS</b>
              </h1>
            </Col>
          </Row>
          <Row>
            {size ? (
              // speakers.map((speaker, index) => (
              //   <Col className="ml-auto mr-auto px-1" lg={3} md={6} key={index}>
              //     <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
              //       <SpeakerCard speaker={speaker} />
              //     </ScrollAnimation>
              //   </Col>
              // ))
              <Col xs={12} className={"mx-auto"}>
                <Slider {...settingsdesktop}>
                  {newspeakers.map((speaker, index) => (
                    <div className="stylepastspeker" key={index}>
                      <SpeakerCard speaker={speaker} />
                    </div>
                  ))}
                  {chairmans.map((speaker, index) => (
                    <div className="stylepastspeker" key={index}>
                      <SpeakerCard speaker={speaker} />
                    </div>
                  ))}
                  {speakers.map((speaker, index) => (
                    <div className="stylepastspeker" key={index}>
                      <SpeakerCard speaker={speaker} />
                    </div>
                  ))}
                </Slider>
              </Col>
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          {/* <Row className='justify-content-center'>
            <Col lg={3}>
              <Button
                href='/speakers'
                className='btn d-block py-2'
                color='primary'
                size='sm'
              >
                <p className='m-0 text-700' style={{ color: "#fff" }}>
                  VIEW ALL
                </p>
              </Button>
            </Col>
          </Row> */}
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close mr-2 mt-2"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

export const newspeakers = [
  {
    name: "Faran Niaz",
    title: "Founder and CEO/ Chair Judge	",
    company: "CX Future",
    image: "Faran Niaz_pic (1).jpeg",
    bio: `
    Faran Niaz is the CEO &amp; Founder of ‘CX Future’. He has over 25 years’ experience as an
accomplished Customer Experience, digital transformation and change management expert and
has managed CX setups globally for Citibank, Mashreq &amp; ADIB. He has been an architect of
winning multiple CX awards and creating a unique record of moving one of the Banks in UAE
from 23rd position to No.1 Bank in customer experience and maintaining it for record 7
consecutive years. He is globally recognized CX Awards Judge &amp; International Motivation &amp;
Keynote Speaker and co-author of Amazon Best Seller book ‘Customer Experience 4 - CX4’<br/><br/>
Faran is helping organizations elevate and transform their customer experience to the next level
with a mission to solve organizations pains in customer engagement across various channels
and processes using advanced CX Maturity Matrix models providing in-depth analysis and
evaluation based on VOC matrics, NPS, CSS, CES surveys, customer centric design,
Employee engagement, Agile journey mapping, digital online and mobile transformation in line
with global CX benchmarks.
    `,
  },

  {
    name: "Shehryar Sheikh	",
    title: "Group Head Customer Experience	",
    company: "JS Bank Pakistan",
    image: "Shreyar_Sheikh_print pic (1).jpg",
    bio: `
    Shehryar Sheikh is a seasoned professional with knowledge of business
transformation, service quality, and customer experience. He currently holds the title
of Head of Customer Experience at JS Bank Limited (JSBL), where developing ways
to improve customer experience and service quality is his main area of responsibility.
Microsoft Dynamics 365 CRM was effectively implemented by Shehryar at a number
of customer touchpoints, which resulted in quicker turnaround times, lower error
rates, and better service level agreements.<br/><br/>
Shehryar held the position of Group Head of Customer Experience and Business
Transformation. He skillfully implemented 25 projects during this time using Agile
methodology and cutting-edge technologies like RPA using UIPath. To enhance the
visibility and tracking of important performance indicators, he also deployed PowerBI
dashboards. Shehryar formerly held the positions of Functional Lead Business
Transformation, at Habib Bank Limited (HBL) and Head of Deposits, Head of
Reengineering and Head of Consumer Insight at Standard Chartered Bank (SCBPL),
where he regularly performed well.
    `,
  },
  {
    name: "Dr Khalil Laghari",
    title: "Head of Service Design and UX Research",
    company: "Majid Al Futtaim Carrefour",
    image: "Laghari.jpg",
    bio: ``,
  },
  {
    name: "Doran Davies ",
    title: " Chief Executive Officer",
    company: "Bounce Middle East",
    image: "Davies.jpg",
    bio: ``,
    isOnlyJudge: true,
  },
  {
    name: "Mathias Marino",
    title: "Chief Executive Officer",
    company: "ModusCX",
    image: "Matthias Marino Headshot.jpeg",
    bio: `Matthias founded ModusCX as a customer-centric management consultancy that specializes in understanding and creating experiences.
    ModusCX focuses on CX transformation projects that build CX frameworks, structures and capabilities within companies and drive customer-centric innovation. Matthias has been consulting companies in the UAE for 13 years and has successfully worked with clients such as Al Ghurair Investments,
     ISP, Aldar, Meraas, Dubai Properties, Chalhoub, A&E, DAFZA and many more.`,
  },
  {
    name: "Ankesh Agarwal	",
    title: "CX Strategy, Center of Excellence	",
    company: "Majid Al Futaim",
    image: "Ankesh Agarwal Headshot.jpg",
    bio: `Ankesh Agarwal, manages the Group CX strategy and CX center of excellence at Majid Al Futtaim. He
    is a top 250 global insights professional, top 100 global CX thought leader and a CX award judge with
    over 16 years of CX experience. Ankesh drives business value creation by crafting memorable
    customer experiences via consumer understanding, digital transformation, and organization
    alignment.`,
    isOnlyJudge: true,
  },
  {
    name: "Ahmad Altarawneh ",
    title: "Senior Transformation Consultant",
    company: "Dubai Police General HQ ",
    image: "Ahmad Al tarawneh Headshot .jpg",
    bio: ``,
  },
  {
    name: "Kowshik Bhattacharjee",
    title: "Head of Service Excellence",
    company: "Medcare Hospitals & Medical Centres",
    image: "_DSF3308.JPG",
    text: [
      `Kowshik Bhattacharjee comes with 23 years of rich experience, encompassing Hospitality/ BPO &amp;
    Healthcare Sector. He has been associated with one of the finest brands all like Taj Hotels/ Marriott/ Le
    Meridien/ ITC Hotels/ Dell Computers/ Apollo Hospitals &amp; currently with Medcare Hospitals and Medical
    Centers, as the Head of Patient Experience, which is one of the premium brands of DM Aster Healthcare
    group in Dubai.<br/><br/>
    During his tenure with Apollo Hospitals, he institutionalized the concept of Service Excellence in
    Healthcare and brought about significant best practices from other Service Industries. Few of his
    projects like Voice of Customer Methodology/ Dial 30/ Tender Loving Care/ AAPIE has been awarded in
    both domestic and international platforms. He is a well renowned name in the field of Customer
    Experience in India and has been conferred with the prestigious “Bharat Gaurav Award” in 2017 by
    Central Ministry.<br/><br/>
    In his new assignment, he is the Head of Service Excellence and is responsible for creating the Customer
    Experience framework for Medcare Hospitals and Medical Centres and playing a key role in
    Transformation. In a span of 2 years, he has been able to accelerate the Service Excellence
    Transformation in Medcare. Under his leadership Medcare has been conferred with Gulf Customer
    Experience Awards for Best Patient Experience &amp; Best CX Team of the Year in Feb 2022.`,
    ],
  },
  //   {
  //     name: "Monalisa Wafta",
  //     title: "Group Director Patient Services",
  //     company: "SEHA Abu Dhabi",
  //     image: "Molisa Watfa Headshot (1).jpg",
  //     bio: `
  //     Monalisa is presently the Group Director of Patient Services at SEHA. She leads efforts to design new
  // services and enhance existing ones with the aim of facilitating patients’ access to services; ensuring
  // their journey is coordinated and seamless; and that their preferences and needs are catered to
  // proactively and consistently.<br/><br/>
  // Prior to that, she held leadership positions at Cleveland Clinic Abu Dhabi, Saudi German Health
  // Group, and IMC. Over the years, her work has been focused on helping healthcare organizations
  // identify and address operational challenges, and provide care that is measurably more patient- and
  // family-centered.
  // <br/><br/>
  // Monalisa holds a Master’s in Business Administration (MBA) and a doctorate of Pharmacy.
  //     `,
  //   },

  {
    name: "Saleh Ali Al Balushi",
    title: "Director - Health Safety and Environment	",
    company: "Drydocks World - a DP World",
    image: "Saleh_Ali_official photo 2023 (1).jpg",
    bio: `Saleh Ali Albalushi has over 22
    years of work experience in the
    Oil &amp; Gas, Aircrafts, Energy,
    Shipyards and Real Estate
    industries, he held senior management positions in reputed
    organizations where he has played a leadership role by developing and implementing a group-
    wide corporate health, safety, environment and Business Continuity Management strategy and
    worked alongside the executive leadership team to provide strategic advice across a range of
    different critical areas.<br/><br/>
    Saleh is an HSE Division Director at Drydocks World, a DP World company, he oversees the HSE
    function that includes Health, Safety &amp; Fire Defence, Environment and Medical departments.`,
  },

  {
    name: "Claire Bosq	BizShui ",
    title: "",
    company: "Creator & CX Thought Leader	",
    image: "Claire Bosq_high res (1).jpg",
    bio: `
    Claire Boscq is the No1 Woman on the Global
Customer Experience Guru with three decades
of expertise, Claire, is an authority in the
Customer Experience Industry.
<br/><br/>
International best seller author with 4 published
books, she is an international media influencer
and her work has been published in Brazil, Philippines, India, US &Europe.
    `,
  },
  {
    name: "Olga Budeiri",
    title: "EX- Head of customer experience	",
    company: "Aramex Jordan",
    image: "Olga Budieri_hi res pic (1).jpeg",
    bio: `Olga Budieri has led customer experience globally Dubai-based international
    courier, transportation and logistics services company Aramex International for over
    12 years, which has equipped her with a laser-focus on customer expectations,
    needs and experience landscape.<br/><br/>
    In 2020, she founded her own training initiative, Customer Experience by Olga, with
    which she is “on a mission to ignite the passion for Customer Experience in the Arab
    region”.<br/><br/>
    Olga is highly experienced in enterprise level experience transformation programs,
    change management, experience design, voice of customer, journey management &
    orchestration, and strategic planning to uplift B2B and B2C experience programs.`,
  },

  {
    name: "Simon O Donnell	",
    title: "Thought Leader	",
    company: "CXSA Middle East",
    image: "Simon O Donell Headshot.jpg",
    bio: `
    Following a successful career working within large
predominantly retail companies, Simon has spent the
last 20 years establishing and running SME’s in both
the Telecoms and Logistics sectors. In addition he has
been advising other businesses on how they can
grow and develop and in some cases survive a
financial markets collapse. All of these have been
customer facing and served to reinforce his belief
that organisational customer centricity is key to every
business’s success.<br/><br/>
He has delivered customer experience programmes
for both the internal and external customer,
across various UK companies and delivered multiple
training and coaching sessions/workshops at various
levels as part of employee engagement programmes.
Simon really enjoys sharing his passion for
customer experience with others and encouraging
them to think differently about the way they
approach customers and the interactions they have
with organisations.
    `,
  },

  {
    name: "Peter Halsor",
    title: "EX- head of Customer Experience	",
    company: "Riyad Bank",
    image: "Peter Halsor Headshot.jpg",
    bio: `
    A passionate advocate of the customer, Peter has led Digital, Customer Experience and
Business Transformation teams to deliver market-leading propositions for several
leading financial institutions in London, Continental Europe and the Middle East.<br/><br/>
As an early digital innovator at Fidelity Investments, Peter led the design & delivery of
the UK’s first online fund supermarket. Later, as Head of Customer Experience at
NEST, Peter led a digital team to define the e-business strategy and customer
experience proposition for a new pension scheme used by over 11 million people to
save for retirement.<br/><br/>
As Chief Customer Experience Officer at Riyad Bank, Peter led a business
transformation programme to drive customer-centricity across the retail bank. He was
responsible for defining the customer experience strategy across all channels and
working within the C-Suite to drive CX transformational change and digital innovation.
As part of this work, Peter implemented a state-of-the-art Customer Experience &amp;
Innovation Laboratory - the first bank in the region to do so.
    `,
  },

  {
    name: "Yasser Al Eitani",
    title: "Head of Customer experience ",
    company: "Design	Tree",
    image: "Yaseer al.jpg",
    bio: `
    Yasser is a seasoned Operations and Customer Experience professional with over 12 years of diverse
experience spanning across industries like Retail, Logistics, Banking, Technology, Insuretech, and
fintech. Throughout his career, Yasser has played a pivotal role in shaping CX and service functions,
driving growth, and enhancing customer satisfaction.
<br/><br/>
His expertise lies in developing performance metrics and growth models, deriving valuable insights,
and establishing voice of the customer programs. Yasser is also well-versed in design thinking,
leveraging it to craft innovative solutions for customer-centric experiences.
<br/><br/>
Passionate about serving customers, Yasser has held various leadership positions focused on
optimizing customer experience and service operations. With his exceptional skills and dedication,
he has successfully built and led CX functions that prioritize customer needs and drive
business success.`,
  },
  {
    name: "Dr. Vinod Vasudevan",
    title: "Group CEO",
    company: "FlyTxt",
    image: "vinod.png",
    bio: `Dr. Vinod Vasudevan is the Group CEO of Flytxt BV, a Dutch company
    pioneering AI-powered Customer Lifetime Value maximisation solutions for
    subscription businesses. Under his leadership, Flytxt has emerged as a fast growing
    technology company enabling data-driven customer experiences for global
    enterprises and vendors like Zain, Ooredoo, MTN, Orange,
    Oracle, and SAP.<br/><br/>
     Vinod brings on board more than 25 years of diverse experience working with
     startup ventures to large multinational corporations across industries spanning
    roles from research to commercialization. He also serves as an independent director
    of ESAF Bank, a new generation bank that strives to promote financial inclusion of
    underserved sections in India.<br/><br/>
    Vinod has been a keynote speaker and panellist at several international technology
    events and holds several patents, peer reviewed and invited publications. He holds a
    Bachelors, Masters and Doctorate in Computer Science and Engineering from IIT
    Kharagpur, where he specialised in artificial intelligence and neural networks.`,
    isOnlySpeaker: true,
  },
  {
    name: "Charles Bennett	",
    title: "Co-Founder and CEO	",
    company: "CXSA Middle East",
    image: "Charles Bennett_high Res pic (1).jpg",
    bio: `A highly experienced Customer Experience Director with over 20 years of expertise in driving impactful
    change programs across customer experience, strategy, project and program management, training,
    coaching, business process management, and business development. As the President & CEO of CXSA
    Middle East, based in the UK and UAE, Charles has a remarkable track record of accomplishments,
    including generating over $500 million in change benefits and training more than 1,000 companies across
    40 countries in next practice CX and Enterprise Business Process Management. With a wide range of
    qualifications and skills, Charles specializes in customer-centric business change and strategy, customer
    experience management, training and coaching, customer innovation, and program and project
    management. His passion lies in helping individuals and organizations adopt new thinking and
    implementing it into their daily work, driving sustained and differentiated outcomes. Charles excels at
    delivering customer outcome-driven change by leveraging his expertise in multiple program and project
    management methodologies, ensuring alignment across various levels of outcomes. As a trainer, keynote
    speaker, and business development professional, he inspires customer-centric thinking, culture, and
    behaviour, making him a trusted leader in creating momentum and driving customer-centric business in the
    G.C.C.`,
  },

  {
    name: "Hassan Amjad Mohammad",
    title: "Co-Founder and Chief Innovation Officer	",
    company: "CXSA Middle East",
    image: "Hassan Amjad Mohammad.jpeg",
    bio: `I help ambitious
    organisations to design &
    build customer centric
    companies. We use the
    latest models in human
    centric design thinking to co-
    create customer-centric
    <br/><br/>
    strategies, drive culture
    change and build capacity
    for CX & Innovation.
    We also specialise in
    developing people's
    capability and passion in CX.
    We have delivered
    workshops, keynotes and
    facilitated training sessions in
    36 countries including Saudi
    Arabia and elsewhere in the
    GCC.`,
  },

  //   {
  //     name: "Dr. Abeer AlSubait",
  //     title: "Corporate Deputy Executive Director	",
  //     company: "Ministry of National Guard Health Affairs",
  //     image: "Dr Abeer Headshot.png",
  //     bio: `
  //     Dr. Abeer AlSubait got her:
  // <br/>Master Degree in Leadership in Health care management from university of
  // Manchester 2020.
  // <br/>Master Degree in Public Health, King Saud Bin Abdulaziz University for
  // Health Science (College of Public Health and Health Informatics) 2012
  // <br/>Diploma degree in Public Health from Liverpool Tropical Medicine 2012
  // <br/>AEGD certificate from King Abdulaziz Medical City-Dental Center - Jan
  // 2004
  // <br/>She got her fellow from ICD in 2013.
  // <br/>Now Dr. Abeer is Corporate Deputy Executive Director for quality and patient
  // safety and patient experience in ministry of national guard health affairs.
  // <br/>Advanced General dentistry Section head in Dental Services, Ministry of
  // national guard health affairs Since 2020, Till March 2023
  //     `,
  //   },
  {
    name: "Shruthi Boosey	",
    title: "Managing partner and Director	",
    company: "Jus General Trading",
    image: "Shruts profile 2 (1).jpg",
    bio: `
    Shruthi Boosey is a highly accomplished professional with an impressive 2.5 decades of experience
in the airline GSA industry an Msc. Major in HRM from PWU, Hawaii. USA. She is a proud GLG
Member, Global Citizen and Member of SheTrades.<br/><br/>
Shruthi comes with a 2.5 decade strong Airline GSA background and has held the office of CEO
during her tenure in the Airline/GSA Industry which represented Delta AirLines, Swiss International
Airlines, Singapore Airlines, SilkAir, Sabena Air Lines, SwissCargo, SIA Cargo and PolarAir Cargo,
Founder Director of Aviation Holidays and Aviation Infotech, a subsidiary of the Airline GSA Aviation
Travels Pvt, Ltd., in Bangalore, India with a successful business model promoted and encouraged
women on the workforce.
    `,
  },

  {
    name: "Haydi Adel El Lamey",
    title: "Head of Customer Journey Design & Culture",
    company: "HSBC",
    image: "Haydi.jpeg",
    bio: "",
    isOnlySpeaker: true,
  },
  {
    name: "Ahmed Al Badawi",
    title: "Head of Customer Experience",
    company: "Riyad Bank",
    image: "Ahmad Al Badawi Headshot.jpeg",
    bio: "",
    isOnlySpeaker: true,
  },
  {
    name: "Dr Tahir Hussain",
    title: "Group Chief of Operations Excellence",
    company: "Abu Dhabi Health Services Company",
    image: "Tahir Hussain.jpeg",
    bio: "",
    isOnlySpeaker: true,
  },
  {
    name: "Karthik Akula",
    title: "Regional Sales Head",
    company: "Middle East - Freshworks",
    image: "Karthik Chandra Akula_Regional Sales Head Freshworks.jpg",
    bio: "",
    isOnlySpeaker: true,
  },
  {
    name: "Volha Smirnova",
    title: "Digital Growth Expert",
    company: "Contentsquare",
    image: "VOLHA SMIRNOVA.jpeg",
    bio: `Volha is the digital growth Manager with Contentsquare, the digital customer experience platform provider, and looks after business development activities in the MENAT region.
<br/><br/>With over 10 years of expertise in enterprise and digital analytics, Volha is driven by her passion for all things digital. Prior to her current role at Contentsquare, Volha dedicated her time to working with organizations that focused on leveraging the power of AI and IoT to facilitate digital transformation.
<br/><br/>Volha is highly experienced in enterprise level digital transformation programs, and strategic planning in public sector & private sector programs, and lead both product and design marketing teams to create better IT products on the market.`,
    isOnlySpeaker: true,
  },
  {
    name: "Bekhos Abboud",
    title: "Digital Growth Expert",
    company: "Contentsquare",
    image: "Bekhos Abboud.jpeg",
    bio: `During his professional journey, Bekhos has been passionately devoted to fostering digital growth and development across diverse industries. Currently working with Contentsquare in the role of Digital Growth Consultant, operating in the Middle East, North Africa, and Turkey, Bekhos leverages cutting-edge strategies to drive business expansion in this dynamic region. Prior to this, he excelled as a Business Developer and strategist in the Digital Marketing industry, contributing to his previous company’s growth in Saudi Arabia.`,
    isOnlySpeaker: true,
  },
  {
    name: "Mai Momani",
    title: "Head of Client Experience",
    company: "Invest Bank",
    image: "Mai Momani.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Biju Nair",
    title: "Assistant Director Customer Experience & L&D",
    company: "Ejadah Asset Management Group",
    image: "biju.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Yash Sultania",
    title: "Co-Founder and CEO",
    company: "Survey 2 Connect",
    image: "Yash Sultania.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Hammad Khan",
    title: "GM- Experience Transformation",
    company: "Designit",
    image: "Hammad Khan.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },

  {
    name: "Alexandra Topalian",
    title: "",
    company: "Master of ceremonies",
    image: "Alexandra.jpeg",
    bio: `
    Alexandra Topalian is a Canadian presenter, overseeing the smooth running of a variety of conferences and events throughout the GCC. Bringing her energy to the stage, she engages speakers and audiences alike, making each conference unique and fully charged.
    `,
    isOnlySpeaker: true,
  },
];
export const speakers2024 = [
  {
    name: "Humaid Hassan Salem Alshamsi",
    title: "Expert in CX and Government Excellence",
    company: "",
    image: "Humaid AlShamsi - Personal Photo.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Ahmed Al Zarooni",
    title: "SVP, Head of Customer Services Division",
    company: "Daman (National Health Insurance Company)",
    image: "Ahmed%20Al%20zarooni.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Dr. Hassa Saif Al Mazrouei",
    title: "Medical Director Patient Experience",
    company: "Sheikh Shakhbout Medical City - SSMC",
    image: "Hassa%20Al%20Mazrouei.png",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Ahmed Khedr",
    title: "Vice President Retail Digital Transformation",
    company: "E& UAE",
    image: "Ahmed_Khedr.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Eman Talaat",
    title: "Head of Experience Design, Department of Government Enablement",
    company: "Abu Dhabi Emirate",
    image: "Eman_talaat.webp",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Yusra Baqi",
    title: "CHRO",
    company: "aafaq Islamic Finance PSC",
    image: "yusra%20pic%20new.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Andreas Markdalen",
    title: "Global Chief Creative Officer​",
    company: "frog, part of Capgemini Invent",
    image: "2022_04_07_FROG_%20Andreas%20Markdaleni_0692.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Gagandeep Gadri",
    title: "Managing Director",
    company: "frog, part of Capgemini Invent",
    image: "Gagandeep%20Gadri.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Stoyan Petrov",
    title: "Middle East Lead",
    company: "frog, part of Capgemini Invent",
    image: "Stoyan%20Petrov%20Copy.JPG",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Himanshu Malhotra",
    title: "Advisor to the Chairman, Department of Municipalities and Transport",
    company: "Abu Dhabi",
    image: "Himanshu%20photo.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Sindhu Sreenath",
    title: "Managing Director",
    company: "QuestionPro MEA",
    image: "SindhuSreenath.JPG",
    bio: ``,
    isOnlySpeaker: true,
  },

  {
    name: "Raluca Berchiu",
    title: "Customer Experience & Marketing Consultant",
    company: "",
    image: "Raluca%20Berchiu.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Charles Bennet",
    title: "President",
    company: "CXSA Middle East",
    image: "Charles%20Bennett.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Suryaveer Singh",
    title: "Head Loyalty CRM & Data",
    company: "ENOC",
    image: "Suryaveer%20Singh.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Yash Sultania",
    title: "Founder & CEO",
    company: "Xebo.Ai",
    image: "YashSultania.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Biju Nair",
    title: "Assistant Director Customer Experience & Service Strategy",
    company: "Ejadah Asset Management Group",
    image: "BijuNair%20photo.jpeg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Julia De Souza",
    title: "Vice President, Customer Experience & Operations",
    company: "American Express Middle East",
    image: "Julia AE.png",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Mai Momani",
    title: "Head of Client Experience",
    company: "Invest Bank P.S.C.",
    image: "Mai Momani photo.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Jihane Srour - Tehini",
    title: "Managing Partner",
    company: "Human2outcome",
    image: "Jihane photo.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Karthik Akula",
    title: "Regional Head - MENA",
    company: "Freshworks",
    image: "Karthik Akula - Photo.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Ankesh Agarwal",
    title: "Director, Customer Experience",
    company: "Majid Al Futtaim",
    image: "Ankesh Agarwal photo 2.jpg",
    bio: ``,
    isOnlySpeaker: true,
  },
  {
    name: "Hassan Mohammad",
    title: "Co-Founder & Chief Innovation Officer",
    company: "CXSA Middle East",
    image: "HassanMohammad.jpg",
    bio: ``,
    isOnlySpeaker: true,
  }
];
