import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top`}
        style={{ minHeight: "50px" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/cx-evolve/" className="p-0" tag={Link}>
              <img
                src={require(`assets/editions/cxevolveLogo.webp`)}
                className=""
                width="100%"
                style={{ maxWidth: "180px" }}
                alt="logo"
              />
              {/* <img
                src={require(`assets/images/CXSA.cb8001f0.jpg`)}
                className=""
                width="100%"
                style={{ maxWidth: "120px" }}
                alt="logo"
              /> */}
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler text-right navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2`}
                    style={{
                      color: scroll ? "#1d1960" : "#1d1960",
                      backgroundColor: "transparent",
                    }}
                    href={s.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className={`fa fa-${s.name}`} style={{ fontSize: "1.5rem" }} />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-700-cx"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-700-cx"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-700-cx"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className="navbar-item-custom text-700-cx"
                      style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) =>
                        i.onClick ? (
                          <DropdownItem
                            onClick={i.onClick}
                            key={i.link}
                            className="navbar-item-custom text-700-cx"
                          >
                            {i.name}
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            href={i.link}
                            key={i.link}
                            className="navbar-item-custom text-700-cx"
                          >
                            {i.name}
                          </DropdownItem>
                        )
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
              <NavItem>
                <NavLink
                  to="/cx-evolve/sponsor"
                  tag={Link}
                  className="btn btn-primary rounded-0 navbar-item-custom text-700-cx text-white px-1"
                  style={{
                    opacity: "1",
                    background:
                      "linear-gradient(289deg, rgba(122,48,115,1) 0%, rgba(205,35,55,1) 28%, rgba(236,112,38,1) 44%, rgba(249,170,42,1) 73%)",
                    border: 0,
                  }}
                >
                  <p className="m-0 text-700-cx" style={{ color: "#fff" }}>
                    SPONSORSHIP OPPORTUNITY
                  </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/cx-evolve/register"
                  tag={Link}
                  className="btn btn-primary rounded-0 navbar-item-custom text-white px-1"
                  style={{
                    opacity: "1",
                    background:
                      "linear-gradient(106deg, rgba(122,48,115,1) 0%, rgba(205,35,55,1) 28%, rgba(236,112,38,1) 44%, rgba(249,170,42,1) 73%)",
                    border: 0,
                  }}
                >
                  <p className="m-0 text-700-cx" style={{ color: "#fff" }}>
                    REGISTER NOW
                  </p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  {
    name: "HOME",
    link: "/cx-evolve/",
    page: undefined,
    items: undefined,
    grid: 1,
  },

  {
    name: "OVERVIEW",
    link: "",
    page: "/",
    items: [
      { name: "ABOUT", link: "/cx-evolve/about" },
      { name: "AGENDA", link: "/cx-evolve/agenda" },
      {
        name: "DOWNLOAD POST SHOW REPORT",
        link: "/cx-evolve/download-psr",
        onClick: () => {
          window.location.href =
            "https://drive.google.com/uc?id=1Fi4YQoy4258jMQ78BPZx0jMSG1H_ohTZ&export=download";
        },
      },
      {
        name: "THE ORGANIZERS",
        link: "/cx-evolve/overview/the-organiser",
      },
      {
        name: "CONTACT US",
        link: "/cx-evolve/overview/contact-us",
      },
    ],
    grid: 1,
  },

  {
    name: "SPEAKERS",
    link: "/cx-evolve/#speakers",
    page: undefined,
    items: undefined,
    grid: 1,
  },

  {
    name: "PARTNERS",
    link: "/cx-evolve/#partners",
    page: undefined,
    items: [
      { name: "2024 SPONSORS", link: "/cx-evolve/#partners" },
      { name: "PAST SPONSORS", link: "/cx-evolve/past-partners" },
    ],
    grid: 1,
  },
  {
    name: "RECOGNITION",
    link: "/cx-evolve/recognition",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "REGISTER FOR AWARDS",
  //   link: "/cx-evolve/register-awards",
  //   page: undefined,
  //   items: undefined,
  //   grid: 1,
  // },

  {
    name: "GALLERY",
    link: "/cx-evolve/gallery",
    page: undefined,
    items: undefined,
    grid: 1,
  },
];

const social = [
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com/KTRemoteWorkforceSummit/",
  // },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/showcase/khaleej-times-events/?viewAsMember=true",
  },
  // {
  //   name: "twitter",
  //   link: "https://twitter.com/IsraelUae",
  // },
];
