import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";

const speakers = [
  {
    name: "His Excellency Dr. Thani Bin Ahmed Al Zeyoudi",
    title: "Minister of State for Foreign Trade",
    company: "Ministry of Economy, UAE",
    image: "thani.jpg",
    text: [
      `H.E Dr. Thani bin Ahmed Al Zeyoudi, was appointed as Minister of State for Foreign Trade in July 2020 to oversee
    the development of non-oil foreign trade and the expansion of global trade partnerships and increase Exports.
    In addition to supervising the portfolio of retaining and attracting the best talents and competencies serving the
    strategic orientation of the UAE, His Excellency also leads the efforts to enhance the UAE’s investment climate in
    order to achieve the country’s economic interests and strategic goals, thereby strengthening its position as a
    prime destination for business and investments at both regional and global levels.`,
      `
    Prior to his appointment as the Minister of State for Foreign Trade, H.E held the position of Minister of Climate
    Change and Environment and supervised the implementation of the country’s strategies at the domestic level,
    for environment and climate action, agricultural development, animal husbandry and fisheries, in addition to
    combating desertification and preserving biodiversity leading to enhance the sustainability of natural systems,
    food diversity and the transition to a green economy.`,
      `
    rough diverse Global participations, HE led the UAE’s efforts to mitigate and adapt to the repercussions of
    climate change, and protect the country's natural ecosystem by developing and implementing a set of eective
    policies and initiatives to achieve sustainable development at both regional and international levels and by
    strengthening existing and new partnerships with the government and private sectors that are capable of
    increasing the contribution of environment, agricultural, livestock and fisheries sectors to ensuring food security
    and biosafety in the country as well as increasing the quality of national production.`,
      `
    Previously and after he served as the Director of the Department of Energy and Climate Change at the Ministry
    of Foreign Affairs and International Cooperation, H.E Al Zeyoudi was appointed as the UAE’s Permanent
    Representative to the International Renewable Energy Agency (IRENA) as extraordinary Ambassador.
    Minister Al Zeyoudi started his career as an oil engineer at the Abu Dhabi Marine Operating Company
    (ADMA-OPCO), and then moved to Abu Dhabi Future Energy Company (Masdar), where he worked on the
    development of renewable and clean energy technologies and solutions, and also played a key role in the success
    of the UAE’s 2009 campaign to host the headquarters of the International Renewable Energy Agency (IRENA) in
    the capital City, Abu Dhabi.`,
      `
    In 2020, HE. was chosen by the Davos World Economic Forum to become a member of the Young Global
    Leadership Council affliated with the Forum, recognizing his outstanding international efforts in the field of
    environment and climate. In 2015, Dr. Al Zeyoudi received the first award for Excellence from the Cooperation
    Council for the Arab States of the Gulf in recognition of his pioneering efforts in the field of renewable energy.
    H.E Dr. Thani Bin Ahmed Al Zeyoudi holds a Bachelor's degree in Petroleum Engineering from the University of
    Tulsa, an MBA from the New York Institute of Technology, an MBA in Project Management from the British
    University in Dubai, and a PhD in Strategy, Program and Project Management from Skema Business School in
    France.`,
    ],
  },
  {
    name: "His Excellency Dr. Tariq Bin Hendi",
    title: "Director General",
    company: "Abu Dhabi Investment Office",
    image: "tariq.jpg",
    text: [
      `H.E. Dr. Tariq Bin Hendi is the Director General of the Abu Dhabi Investment Office (ADIO).
    Serving as the Director General and CEO, ADIO has been His Excellency’s professional home since
    October 2019.<br/>
    Under his leadership, ADIO has launched international offices around the world with the first office
    being in Tel Aviv, Israel. Devoting his career to progress, bold ideas and teamwork, His Excellency
    has positioned ADIO to always seek new ideas and challenges in order to promote Abu Dhabi
    globally.<br/>
    Prior to joining ADIO, His Excellency held leadership roles at various institutions, including Emirates
    NBD, Mubadala and Citibank. He also sits on a number of boards, including AXA, GCIC and Emirates
    Post Group.<br/>
    His Excellency earned his stripes in professional finance at Citi Bank in London. With a joint MBA
    from LBS and Columbia, he then joined Mubadala in the UAE sovereign wealth sector.
    His Excellency holds a PhD in Economics from Imperial College, London, as well as graduate
    degrees from Columbia University and London Business School.`,
    ],
  },
  {
    name: " His Excellency Pavan Kapoor",
    title: "",
    company: "Indian Ambassador to the UAE",
    image: "pavan.jpg",
    text: [
      `In a diplomatic career spanning over three decades, Ambassador Kapoor has
    served in different capacities in the Indian Missions in Moscow, Kyiv, London and
    Geneva apart from serving in the Ministry of External Affairs and the Prime
    Minister’s Office in New Delhi. He also served as an international civil servant with
    the Commonwealth Secretariat in London.
    He was India’s High Commissioner to Mozambique and the Kingdom of Swaziland
    from January 2014 to early 2016, and Ambassador to Israel from March 2016 to
    September 2019.
    Ambassador Kapoor has an MBA from the Indian Institute of Management in
    Ahmedabad, India and a Masters in International Political Economy from the
    London School of Economics.`,
    ],
  },
  {
    name: "Her Excellency Marcy Grossman",
    title: "Canadian Ambassador to the UAE",
    company: "",
    image: "marcy.jpg",
    text: [],
  },
  {
    name: "Her Excellency Ambassador Houda Nonoo",
    title: "Ambassador at Ministry of Foreign Affairs",
    company: "Kingdom of Bahrain",
    image: "nonoo.png",
    text: [
      `H.E. Ambassador Houda Nonoo currently serves as an Ambassador at the Ministry of Foreign Affairs (MoFA). She served as Ambassador Extraordinary and Plenipotentiary of the Kingdom of Bahrain to Washington DC and non-resident Ambassador to Argentina, Brazil, Canada and Mexico. Prior to joining MoFA, she was appointed by His Majesty King Hamad Bin Salman Al Khalifa to the Shura Council, where she served on the committee for Finance and Economic Affairs between 2006 and 2008. 
    Ambassador Nonoo is the first – and to this day, the only – Jewish ambassador to be appointed from an Arab country.
    `,
    ],
  },
  {
    name: "Hamad Abdullah Al Mehyas",
    title: "Chief Executive Officer",
    company: "National Health Insurance Company – Daman, UAE",
    image: "hamad.jpg",
    companyImage: undefined,
    text: [
      `Hamad Abdullah Al Mehyas serves as Daman’s Chief Executive Officer. Under Mr Al Mehyas’
      leadership Daman continues to be the leading health insurer and value-sharing health
      partner to the Government.`,
      `Mr Al Mehyas has overseen the development and implementation of key strategic initiatives
      to provide unparalleled smart solutions and services to help shape and support a healthier
      community in the UAE. His extensive knowledge within the healthcare system has helped
      enhance Daman’s industry leading expertise and ensured the delivery of excellence across
      all its products and services. Prior to his current role, Mr Al Mehyas has served in operational
      and commercial executive roles during his six years at Daman.`,
      `He is one of the first UAE Nationals specialising in Forensic Medicine where he received the
      Government Excellence Award for Innovative Initiative. He has held several senior roles in
      Federal and Local Government entities and led the Office of UAE National Supreme
      Biosecurity Committee. He is a licensed Forensic Medicine Specialist, a member of the
      International Society for Forensic Genetics, Germany. Additionally, and was a member of the
      UK Forensic Science Services.`,
      `Mr Al Mehyas serves as General Manager of Daman Healthcare Solutions, as Board Member
      at inHealth - Independent Health Information Technology Services and as Board member at
      Saudi Enaya Cooperative Insurance Company. He also sits at Emirates Insurance
      Association’s Board as Vice Chairman, Chairman of Higher Technical Committee as well as
      Chairman of the Life &amp; Medical Committee and Chairman of the Emiratisation Committee.
      Mr Al Mehyas holds a master’s degree in DNA Profiling from the University of Central
      Lancashire and a Bachelor of Science in Forensic Medicine from Glamorgan University.
      During his career he has been awarded several honorary medals and certificates of
      appreciation from Government and International Organisations.`,
    ],
    uae: true,
  },
  {
    name: "Jerome Droesch",
    title: "Chief Executive Officer",
    company: "Cigna MEA & SEA",
    image: "jerome.jpg",
    companyImage: undefined,
    text: [
      `Based in Dubai, Jérôme Droesch is the Chief Executive Officer of Cigna in the
    Middle East &amp; Africa (MEA) and South East Asia (SEA) region, Board Member for
    Cigna’s operations in India and Turkey, and Senior Executive Officer &amp; Chairman
    of Cigna Insurance Management Services Board in the Dubai International Financial
    Centre (DIFC).<br/>
    In his capacity, he is responsible for advancing the company’s operations as the
    premier provider of both health and wellness insurance services to individuals,
    groups and government entities across the MEA and SEA region, Turkey and
    India.<br/>
    Under Jerome’s leadership in 2019, Cigna’s Middle East business strengthened its
    position as an industry leader, achieving growth and stability, whilst acquiring
    numerous industry accolades along the way, including Health Insurance Company
    of the Year at both the MENA IR and MIIA awards respectively.
    In addition, he oversees Cigna’s locally regulated offices in the MEA region and all
    markets across SEA, as well as the Regional Health Solutions portfolio, and the
    implementation of high-level strategies for ensuring the long-term profitable growth of
    the organization.<br/>
    Prior to joining Cigna, Jérôme was with AXA Group in an illustrious career spanning
    over 27 years, where he held various management positions across multiple
    geographies and business.<br/>
    Jérôme has a Masters in Engineering from Ecole Centrale, a Masters in Wealth
    Management from the SKEMA Business School, and has completed his further
    management studies from INSEAD.`,
    ],
    uae: true,
  },
  {
    name: "Jihad Faitrouni",
    title: "Chief Executive Officer",
    company: "Dubai Islamic Insurance & Reinsurance Company",
    image: "jihad.jpg",
    companyImage: undefined,
    text: [
      `Mr. Jihad Faitrouni's life career started in 1990 in ARUNCO till 1999 and became an advisor for MOE-
    Lebanon from 1995 till 1997, then as the GM for UIS from 1999 - 2001. From 2001 - 2004
    he was the CEO of Aman Takaful Insurance - Lebanon and then he moved to UAE as a GM
    for Dubai Islamic Insurance &amp; Reinsurance (AMAN) where he worked on a special
    assignment along his duties as a GM, in establishing Kuwait Boubyan Insurance until 2008.
    From 2010 to 2013, he was assigned as an advisor in the MOF- UAE and in 2013 he was
    back to AMAN-Dubai as the CEO.
    He was approached by Sorbonne University Abu Dhabi as a lecturer.
    Professional Fellowship :
    <br/>
    1. Chairman of  the Takaful Technical Committee (EIA)-UAE<br/>
    2. Governing Council Member of IBG<br/>
    3. Member of the Emirate Insurance Association (EIA)- UAE<br/>
    4. Member of Hawkama Institute for Corporate Governance (DIFC)- UAE<br/>
    5. Member of the IRM- UK`,
    ],
    uae: true,
  },
  {
    name: "Walter Jopp",
    title: "Chief Executive Officer",
    company: "Zurich Insurance Company",
    image: "walter.png",
    companyImage: undefined,
    text: [
      `Walter Jopp is Chief Executive Officer for Zurich in the Middle East. His role is
    responsible for developing and executing Zurich’s strategy in the region, enhancing the
    value of Zurich’s strategic partnerships and identifying opportunities for further
    profitable growth.<br/>
    In his career at Zurich, spanning over 26 years, Walter has held several senior roles
    within Asia Pacific, Europe, Latin America and the Middle East. These have included
    developing key bank relationships at a global level, driving Zurich’s international
    business in Latin America and Scandinavia as well as acting as Global Head of Sales,
    building and leading sales operations for individual and corporate businesses with
    teams in the Americas, Europe, Middle East and Asia.<br/>
    Until October 2015, he led the Market Management team for Zurich in Asia Pacific and
    Middle East, overseeing sales, marketing and propositions in the region.`,
    ],
    uae: true,
  },
  {
    name: "Mohamed Seghir",
    title: "Chief Executive Officer",
    company: "AXA Green Crescent Insurance Company",
    image: "Mohamed.jpg",
    companyImage: undefined,
    text: [
      `Mohamed started his career in 2007 as an Actuary at FWU AG in Munich, where he was
    responsible for Western Europe and the Middle East. He then took up the role of Manager
    Actuarial Services for Munich Re before joining Swiss Re in Zurich in 2013, initially as a
    Senior Marketing Actuary in charge of pricing of the life & health business in MENA, Turkey
    and Pakistan before becoming the Head of North Africa (P&C and L&H) and Middle East
    (L&H). Mohamed has been appointed CEO of AXA Green Crescent in April 2018.<br/>
    Mohamed is a regular contributor to seminars, conferences and publications for the
    insurance industry. Passionate about insurance, Mohamed has developed a deep
    expertise on various topics such as bancassurance, life insurance, takaful and pension,
    only to name a few.<br/>
    Mohamed studied in the prestigious ISFA (Institut des Sciences Financières et des
    Assurances) and holds an MSc in Finance and Actuarial Sciences and a BSc in Finance
    from Université Claude Bernard (Lyon, France), and is a fully qualified actuary from the
    French (IA) and Swiss (SAV) Institutes of Actuaries.`,
    ],
    uae: true,
  },
  {
    name: "His Excellency Fahad Al Gergawi",
    title: "CEO",
    company: "Dubai FDI",
    image: "fahad.jpg",
    text: [
      `His Excellency Fahad Al Gergawi is the Chief Executive Officer of
    the Dubai Investment Development Agency (Dubai FDI), an
    agency of Dubai Economy – Government of Dubai. In his Current
    role he leads a team that develops, promotes and supports the
    foreign direct investment (FDI) environment and projects in
    Dubai.
    
    H.E. is the President of the World Association of Investment Promotion Agencies (WAIPA),
    leading the global FDI body from 2019 to 2021. He also serves as the Secretary General of
    Hamdan Centre for the Future of Investment (HCFI), where he leads a global program to
    promote "Impact FDI" as a key to achieve Sustainable Development Goals (SDGs). Al Gergawi is
    a member of Dubai Economy’s Executive Committee; chairs the Dubai Investment Week
    organizing committee and sits on the advisory board of MODUL University in Dubai.
    
    Al Gergawi holds a Bachelor degree from the United Arab Emirates University, and is a graduate
    of the elite Mohammed Bin Rashid Al Maktoum inaugural program for Young Business Leaders.`,
    ],
  },
  {
    name: "Amit Malhotra",
    title: "General Manager – Personal Banking Group",
    company: "Commercial Bank of Dubai, UAE",
    image: "amit.jpg",
    text: [
      `Amit Malhotra heads the Personal Banking Group in CBD and is
      responsible for leading and transforming the retail bank division
      on its journey towards digitization and customer centricity. Prior to
      joining CBD, Amit was Senior Managing Director and Group
      Retail Banking Head at Al Hilal Bank in Abu Dhabi. Before moving
      to the UAE, he held senior retail and credit roles in Standard
      Chartered Bank in Singapore, Vietnam and India as well as
      similar roles in Citibank in Thailand
      and India. Amit holds a BCom (Hons) from Delhi University and a
      Post Graduate Diploma in Business Management from the
      Institute of Management Technology, India. Amit is a trained Six
      Sigma Master Black Belt and was a founding member of
      www.makemytrip.com, India’s largest online travel company with
      market cap of over $1.2 billion.`,
    ],
  },
  {
    name: "Tristan Brandt",
    title: "Chief Digital  Officer",
    company: "Saudi British Bank",
    image: "tristan.jpg",
    companyImage: "CBD.png",
    text: [
      `Tristan is the Chief Digital Officer for Saudi British Bank, SABB. He specializes in digital transformation and delivery
    with 20+ years of expertise setting up new ventures and driving operational change for leading financial services
    organizations including: Fidelity Investments (Asset Management); Aviva (Insurance); RBS (Retail banking). He was
    worked as a corporate executive, strategy consultant, and FinTech entrepreneur helping to co-found a secure
    messaging app Qwil Messenger.`,
    ],
    uae: true,
  },
  {
    name: "Dhiraj Kunwar",
    title: "Managing Director, Business Banking",
    company: "RAKBANK",
    image: "dhiraj.jpg",
    companyImage: "CBD.png",
    text: [
      `Dhiraj Kunwar joined RAKBANK in March 2016 and was
    appointed as Managing Director of Business Banking in
    January 2017. Dhiraj leads the Business Banking
    division’s commercial strategy, business development
    and tactical planning. Prior to joining the Bank, Dhiraj
    spent 5 years at ADCB where he was responsible for
    sales and coverage of SME business as senior vice
    president. Dhiraj has over 19 years of banking
    experience in a wide range of roles across India and the
    UAE with organizations like Mashreq, HSBC and ICICI
    Bank. Dhiraj has completed the EPGM from MIT Sloan
    School of management Boston USA. He is a certified
    associate from the Indian Institute of Banking &
    Finance and also holds a Master’s degree in Business
    from The Institute of Chartered Financial Analysts of
    India. Dhiraj currently chairs the UAE Banks
    federation’s SME Committee, which advises
    government entities and the Central Bank of the UAE
    on matters related to Banking.`,
    ],
    uae: true,
  },
  {
    name: "Syed Mohammad Ali Naqvi",
    title: "Head of AI, Data & Analytics",
    company: "Al Hilal Bank",
    image: "syed.jpg",
    companyImage: "CBD.png",
    text: [],
    uae: true,
  },
  {
    name: "Jamal Saleh",
    title: "UAE Banks Federation",
    company: "Director General",
    image: "jamal.jpg",
    companyImage: "CBD.png",
    text: [
      `Jamal Saleh joined UBF (UAE Banks Federation) in July 2018 and became UBF's Director
    General on 1st January 2019. Prior to joining UBF, his last role was Head of Credit &amp; Risk and
    Acting Regional Chief Risk Officer with a leading GCC bank.<br/>
    Jamal spent 29 years in banking (Conventional and Islamic) and covered Credit and Enterprise
    Risk Management (Credit, Market, Operational, Liquidity, and IT Risks).<br/>
    His first 10 years were in the USA (in Private Banking and Credit) and the last 19 were in the
    UAE in Corporate Banking, Credit, and Risk Management with three leading UAE banks.<br/>
    He served as PRMIA's UAE Director General for 7 years (2007-2014) and was concurrently
    Member on British University's Advisory Board.<br/>
    Jamal graduated valedictorian, summa cum laude, from New Jersey (USA) in Finance and
    Banking, and held 6 professional certifications in credit, finance, investment, and risk
    management.`,
    ],
    uae: true,
  },
  {
    name: "Wissam Fattouh",
    title: "Secretary General",
    company: "Union of Arab Banks ",
    image: "fattouh.jpg",
    companyImage: "CBD.png",
    text: [],
    uae: true,
  },
  {
    name: "Daniel Robinson",
    title: "Head of  Wealth and Personal Management",
    company: "HSBC",
    image: "Daniel.jpg",
    companyImage: "CBD.png",
    text: [
      `I am the Head of Wealth & Personal Banking for the UAE, leading an
    exciting business focused on meeting the everyday needs of our
    personal customers. We are here to support on everything from
    making simple payments to advising on investments and future
    planning. We deliver all this through a number of channels including
    Wealth advisors, Branch Banking, Telephone, Online Banking and
    Mobile.
    <br/>
    I have been based in Dubai for over a year but have 21 years
    experience in financial services from my time in the UK and European
    teams. Professionally my two passions are Customer and Inclusion,
    building high performing teams around both. I am the Co Chair of
    Balance, our gender focused Diversity and Inclusion committee
    across the Middle East, North Africa and Turkey.
    <br/>
    Personally I enjoy nothing more than staying fit and time with family,
    my wife Mallory who is a small business owner here in Dubai and
    two daughters Lily (9) and Ella (5).`,
    ],
    uae: true,
  },
  {
    name: "Abubaker Al Balushi",
    title: "Head of the Digital Banking",
    company: "Bank Dhofar",
    image: "Balushi.jpg",
    companyImage: "CBD.png",
    text: [
      `Mr. Abubaker Al Balushi is the Head of the Digital Banking Division at BankDhofar the 2nd largest bank
    in Oman. He holds a Master in Business Administration in Information Technology from Coventry
    University, UK. He has rich and extensive professional experience of more than 18 years in the
    Technology and Digital Innovation domain; primarily in Oman and also in the United Kingdom spanning
    sectors such as Education, Aviation and Banking. Mr. Abubaker is leading the Digital Banking agenda of
    BankDhofar which is well known for its digital initiatives advancement. His wealth of experience includes
    leadership, strategy, delivery of digital initiatives and innovation, thereby adding value for the Banks’
    customer. He is passionate about the impact of technology &amp; innovation on society and worked on
    several national projects of social relevance.`,
    ],
    uae: true,
  },
  {
    name: "Maisa J Shunnar",
    title: "Group Chief Digital Transformation",
    company: "National Bank of Bahrain Group",
    image: "maisa.jpg",
    companyImage: "CBD.png",
    text: [
      `Maisa has substantial experience in Digital Transformation and Business Process Re-engineering (BPR).
    She champions change and transformation across the Group, driving strategic business transformation
    initiatives aimed at streamlining Group’s functions to achieve optimal performance.
    She is responsible for the coordination of multiple automation projects and initiatives for improving
    customers & users experience by having more efficient and straight through processing. She has the
    bird’s eye-view of all automation initiatives within the Group to ensure collaboration among the
    business units and effective usage of the Banks’ resources.<br/>
    Maisa is a notable speaker and active participant on topics such as FinTech and Digital Transformation.
    She participated in many conferences within the Middle East & Europe. Furthermore, she is a member in
    many national committees in the fields of Fintech and Innovation. Additionally, Maisa serves on the
    Advisory Board of Bahrain FinTechBay and she represents the Group in Central Bank Digital Lab Finx22.<br/>
    Prior to holding her current position in BisB, Maisa worked in the National Bank of Abu Dhabi as
    Transformation Manager, headed the BPR Department in the Electronic Government Authority of
    Bahrain and she served as the Manager of Performance and Productivity Improvements for Electronic
    Data Systems - Bahrain. Prior to moving to the Middle East, Maisa worked in Shell Oil and Gas and for
    the City of Houston in the State of Texas, USA. Maisa holds a B.Sc. degree in Business Administration
    majoring in Computer Information Systems from the University of Houston (Texas, USA), and a master’s
    degree in business communication and Leadership from Jones International University (Colorado, USA).
    Furthermore, Maisa has acquired a Certificate from Oxford University on Fintech, a Diploma in Islamic
    Finance Studies from CIMA, UK, a Certificate in Business Excellence from Columbia Business School in
    Fintech, Digital and Analytics and a Post Graduate Diploma in Design Thinking and Strategy Innovation
    from MIT.`,
    ],
    uae: true,
  },
  {
    name: "Ahmed Mohamed Al Naqbi",
    title: "Chief Executive Officer",
    company: "Emirates Development Bank",
    image: "Naqbi.png",
    companyImage: "CBD.png",
    text: [],
    uae: true,
  },
  {
    name: "His Excellency Abdulaziz Alnuaimi",
    title: "Assistant Undersecretary",
    company: "Ministry of Economy, UAE",
    image: "alnuami.jpg",
    companyImage: undefined,
    text: [``],
    uae: true,
  },
  {
    name: "Saqr bin Ghalib",
    title: "Director of the AI Office",
    company: "",
    image: "saqr.jpg",
    companyImage: undefined,
    text: [``],
    ksa: true,
  },

  {
    name: "Francois Orhan",
    title: "Director - Payment, Loyalty & Data",
    company: "Alshaya Group",
    image: "Francois.jpg",
    companyImage: undefined,
    text: [
      `François Orhan is a French national who has spent the past 20 years working in Loyalty and
    Payment strategy and operations.  He started his career in Paris for Royal Dutch Shell
    Petroleum in various Marketing and Communication roles.
    In 2007 he moved to London to set up the Global CRM practice for Shell Global using
    Loyalty and Payment data to create value for customers and businesses.  In 2015 François
    joined HSBC to coordinate the transformation of all the data and CRM marketing technology
    stack for the Bank.  Before joining Alshaya in 2020 François was Group CRM Director for
    the GVC-Ladbroke-Coral group in charge of CRM and customer journeys.  In his current role
    he leads the Alshaya team in charge of Loyalty, CRM, Payments and Data.`,
    ],
    uae: true,
  },

  {
    name: "Frederik Bisbjerg",
    title: "Executive Director, Digitization",
    company: "Daman National Health Insurance company",
    image: "frederik.jpg",
    companyImage: undefined,
    text: [
      `Frederik Bisbjerg (born 1973 in Denmark) is a highly respected international business developer with expertise in digital transformation and business model innovation. His proven and numerous successes with worldwide business transformations comes from his belief in enabling and empowering people to execute on the change strategies`,
      `He is Chief Operations Officer at Noor Takaful Insurance, the second largest takaful insurer in the UAE, and Head of MENA & Digital Transformation Specialist with The Digital Insurer (Singapore), the World’s largest digital insurance knowledge base with more than 30,000 members`,
      `With The Digital Insurer, he is part of the World’s first mini-MBA in Digital Insurance, where he lecturers in the topics of Strategy and Transformation, Big Data and Best Practice Tech Architectures`,
      `He’s been Executive Vice President for Qatar Insurance Group (QIC), the largest composite insurer in the MENA region and one of the largest insurers in Asia`,
      `Based in the Middle East since 2013, he’s a sought-after keynote speaker and expert for local and international business innovation and development, working with companies seeking to leverage digital opportunities`,
      `Before joining QIC, Frederik was running the worldwide operations of SOS International, then the World’s largest travel claims and emergency assistance provider`,
      `With SOS International, he established companies in China and expanded SOS International’s Asian operations, securing the company a consistent two-digit growth`,
      `Frederik has strong business acumen and is recognized for his ability to build businesses and create new, often previously unseen, alliances between business partners for mutual benefits; a skill refined and honed through many years of working internationally for a top-tier management consulting firm`,
    ],
    uae: true,
  },
  {
    name: "Philip Smith",
    title: "Digital Group Director",
    company: "Kamal Osman Jamjoom Group",
    image: "philip.png",
    companyImage: undefined,
    text: [``],
    uae: true,
  },
  {
    name: "Sridhar Karlapudi",
    title: "Group Head of Operations",
    company: "Abu Dhabi Islamic Bank",
    image: "sridhar.jpg",
    companyImage: undefined,
    text: [
      `Sridhar is Group Head of Operations at ADIB with responsibility for Retail Operations, Corporate &
      International Banking Operations, Business Continuity, Internal Controls and the Analytics Center of
      Excellence.`,
      `Sridhar is a Global O & T & Banking Leader with rich experience in multinational & regional
      organizations like: Citibank, Barclays, Mashreq & the Tata group.`,
      `He has a market leading track record in managing large scale multi geography operations, leading
      digitization programs to deliver transformation, service improvement and substantial cost reductions.
      He specializes in implementing end to end customer straight through process journeys thereby
      increasing efficiency, significantly improving turnarounds and successfully managing a strong control
      and governance framework.`,
      `Sridhar is a regular speaker at international industry events, and his innovation & digitization projects
      have won global recognition from Gartner, Asian Banker, Microsoft, Swift and Blue Prism.`,
    ],
    uae: true,
  },
  {
    name: "Ali	Alajme",
    company: "Ministry of Health and Prevention",
    title: "Director, Digital Health Department",
    image: "alajme.jpg",
    companyImage: undefined,
    text: [
      `Ali Alajme has a Master degree in Executive MBA from Higher Colleges of Technology. He graduated
    from Mohammed Bin Rashid Government Innovation Center in Association with Cambridge university of
    UK. He is an alumni of Mohammed Bin Rashid Leadership development program in Executive leadership
    associated with Duke University. He is a certified Kaplan-Norton Balance Scorecard and Strategy
    Execution from Palladium. He is Alumni of Government Experience Exchange Trainers from UAE prime
    minister office program. Ali Alajme worked in many initiatives one of them is National Unified Medical
    Record of UAE and UAE smart initiative. He was awarded of providing the best mobile service in
    government summit 2015. Ali Alajme worked in many committee such as AI data committee, Smart
    Services committee, Arab Health Committee and federal committee of adoption of 4th industry
    generation. Ali Alajme was honored to be CIO of year 2020 by IDC/IDG due his work in technology
    during the pandemic. Ali Alajme has worked in many board of directors in the private sector where his
    experience in building their strategies helped the companies to prosper and increase their wealth. He
    has 16 years of experience when he started as an engineer in Department of Economic development in
    Dubai to Director of Digital Health Department in Ministry of Health and Prevention.`,
    ],
  },
  {
    name: "Mubaraka Ibrahim	",
    company: "Ministry of Health and Prevention",
    title: "Director",
    image: "mubaraka.jpg",
    companyImage: undefined,
    text: [
      `Currently the Director of IT for The Ministry of Health and
    Prevention, Mubaraka Ibrahim is, in many ways, the face
    of Healthcare IT – not only in The UAE but in the Arab
    region in general. Possessing an impressive career track
    record, spanning over 20 dynamic years, and affiliating
    with many world renowned IT organizations, she has
    participated as keynote speaker in endless conferences,
    exhibitions and discussions pertaining to the imperative
    role IT plays in today’s healthcare.
    Possessing a bachelor’s degree in Computer Science from
    Kuwait University and Master Degree in Information
    Management systems from The American University in
    Washington along with a Healthcare Leadership diploma
    from The Harvard School of Public Health, Mubaraka’s
    passion in the relationship between Health and
    Information Technology is easily recognized. Her key
    involvement in the multiple national healthcare technology
    projects, such as Riayati (National unified medical Record)
    Track & Trace, are just a few of many on her resume – she
    wasn’t named in the top 50 CIO and top Female Executives
    Middle East for no reason – these are just a few accolades
    to mention.`,
    ],
  },
  {
    name: "Dr. Hamed	Ali Al-Hashemi	",
    company: "Director of strategy",
    title: "Department of Health, Abu Dhabi",
    image: "hamed.png",
    companyImage: undefined,
    text: [
      `He is currently a Director of Strategic Affairs at Department of Health
    (Abu Dhabi) overseeing the healthcare strategic planning and
    performance management. He is also leading digital healthcare
    transformation programs including accelerated adoption of artificial
    intelligence, building big data analytics capacity, heath information
    exchange, AI &amp; Innovation Lab and foresights in healthcare.
    Previously, he served as Director for Government Strategy and
    Performance at General Secretariat, Executive Council of Abu Dhabi
    Government. He also worked as the Director of Economic Policy
    Planning at Abu Dhabi Council for Economic Development. He started
    his career in the oil and gas industry with Abu Dhabi Gas Liquefaction
    Company Ltd. (ADGAS) where he headed the development, planning
    and engineering divisions.
    His expertise spanning strategy formulation, policy-making, digital
    transformation, outcome and result based performance
    management, design thinking, scenario plannin, project management,
    and building institutional capabilities.
    He holds a master’s in public administration from Harvard Kennedy
    School and a doctorate in Business Administration from Cranfield
    University.`,
    ],
  },
  {
    name: "Ayesha Al Falahi",
    company: "Dubai Healthcare City Authority",
    title: "Senior Director, Professional Licensing",
    image: "ayesha.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: "Ahmad	Yahya	",
    company: "American Hospital Dubai",
    title: "Chief Operating officer",
    image: "ahmad.png",
    companyImage: undefined,
    text: [
      `With over 25 years of experience, Ahmad F. Yahya is a senior IT professional who has worked across the
    USA and the Middle East and holds a Master’s degree in computer science from Western Kentucky
    University, 1990. A dynamic, success-oriented leader and a team builder, Yahya is reputed for delivering
    technology solutions and creating an amicable IT ecosystem for organizations to function optimally. He
    also has experience in handling large IT departments along with advising and counseling departments
    for better operations and implementations of tech-strategies.
    
    Currently working as the Chief Operation Officer at American Hospital, Yahya delivers and oversees the
    IT strategy with a focus on digital transformation that enables innovation, growth, and efficiency, along
    with assessing the internal technological capabilities. Earlier, he has worked with UAE Medical Service
    Corps, Sheikh Khalifa Medical City, Abu Dhabi, and Dubai Healthcare City. In the USA, he has worked
    with El Paso Corporation, Houston, and Scientific Software Inter-comp Houston.`,
    ],
  },

  {
    name: "Aliasgar	Bohari",
    company: "Zulekha Hospitals	",
    title: "Senior Director  Information Technology",
    image: "aliasger.png",
    companyImage: undefined,
    text: [
      `A self-confessed networking wizard, Mr. Aliasgar
    is by qualification an Industrial Electronics
    Engineer who did his specialization in Hardware &
    Networking. Having spent most of his professional
    career spanning over13 years in Information &
    Communication Technology, Mr. Aliasgar is a
    dynamic innovator who strives continuously to
    improve the contribution of IT in healthcare from
    the usual supportive role to an operational
    business tool.<br/>
    Mr. Aliasgar was part of the original team
    responsible for designing and implementing the
    HMIS in 2004 for
    Zulekha Hospitals. A hands-on professional, he
    was instrumental in the 24-month implementation
    from the onset at both multi-specialty hospitals in
    Dubai and Sharjah. This HMIS module was soon
    augmented with access for the four medical
    centers thereby completely integrating the
    Zulekha Healthcare Group in a single seamless
    network.<br/>
    Another important IT upgrade coordinated by Mr.
    Bohari was to implement a custom-designed IT
    Infrastructure in 2005 that complemented and
    catered to the total healthcare needs of Zulekha
    Healthcare Group. Mr. Bohari was also at the core
    of designing and implementing the paperless
    ‘Intranet’ solution for both multi-specialty facilities
    in 2006 at Dubai and Sharjah and also a website
    for patient’s to know more about hospital and
    events, to book an online appointment, to view
    online patient’s reports.`,
    ],
  },
  {
    name: "Augustine	Amusu	",
    company: "Mediclinic City Hospital",
    title: "Chief Information Officer",
    image: "augutine.png",
    companyImage: undefined,
    text: [],
  },
  {
    name: "Jaleel	Rahiman",
    company: "Prime Healthcare Group",
    title: "Director of IT and Prime Digital",
    image: "jaleel.jpg",
    companyImage: undefined,
    text: [
      `Mr Jaleel Rahiman is an IT leader with over 24 years of experience in diverse organizations, and has
    expertise in IT Service Management, Change Management, Project Management, Team
    Management, Solutions Delivery, and IT infrastructure design.
    He holds a Bachelor of Science in Computing from the University of Portsmouth and Master of IT
    Management from the University of Wollongong.
    Currently heading the IT division of Prime Healthcare Group, Mr Jaleel has also served in senior
    positions at GlaxoSmithKline (GSK) and Abu Dhabi National Insurance Company (ADNIC).
    He is a collaborative leader with a keen vision to understand business challenges and opportunities,
    cultivate effective communications, and influence business strategy and direction. He guides his
    teams to deliver reliable, scalable, and secure IT services and solutions aligned with both defined
    and emerging requirements.`,
    ],
  },

  {
    name: "Ramakrishnan	Natarajan",
    company: "Emirates Hospitals Group",
    title: "Vice President -  Information Technology",
    image: "rama.jpg",
    companyImage: undefined,
    text: [
      `An IT thought leader with over 16 years of experience in various spheres of Solution Delivery, Operations
    Management, Enterprise Architecture, Information Security within multiple industry sectors including
    Healthcare, System Integrators, Aviation, Banking and Government. Currently he is spearheading the IT
    Vertical for Emirates Hospitals Group including 2 hospitals, around 25+ clinics, pharmacies, day-surgery
    centers and rehabilitation facilities with focus on Strategic Planning and initiatives, Organizational
    Development, IT Roadmap and Infrastructure Planning, Application modernization, ROI-Focused Rapid
    Large-Scale Deployments and System Architectures Modernization among others.`,
    ],
  },
  {
    name: "Veneeth	Purushotaman	",
    company: "Aster DM Healthcare	",
    title: "Group Chief Information Officer",
    image: "vineeth.png",
    companyImage: undefined,
    text: [
      `Mr. Veneeth has over two decades of experience in leading technology enablement and transformation across Retail, Education
    and Healthcare sectors. Before joining the Healthcare sector in 2016, he has worked in the Retail sector for over 16 years driving
    large technology transformation projects working with companies like Bharti Retail and Shoppers Stop group.
    An ISB-Kellogg School of Management alumni and a Certified Healthcare CIO (CHCIO), has also been instrumental in setting up the
    CHIME India chapter and serves as a board member and joint secretary.
    He is a CIO Hall of Fame honoree and was conferred the Images Most Admired CIO of the Year – Retail in 2015 by the Images Retail
    organization. He is also awarded India’s Top 50 CIOs by CIO Association of India.`,
    ],
  },
  {
    name: "Dr. Mariam  	Al Jalahma",
    company: "Chief Executive Officer",
    title: "National Health Regulatory Authority, Bahrain",
    image: "mariam.jpg",
    companyImage: undefined,
    text: [
      `Appointed by His majesty the King of Bahrain, in 2015 as the Chief Executive Officer of the
      National Health Regularity Authority in the Kingdom of Bahrain. Member of supreme
      Council of Health, Member of the National Task Force for competing Covid19.`,
    ],
  },
  {
    name: "Ahmed AlZahrani",
    company: "Ministry of National Guard Health Affairs - Saudi Arabia",
    title: "Director of Healthcare Technology Development",
    image: "ahmed.jpg",
    companyImage: undefined,
    text: [
      `Ahmed AlZahrani, Director of Healthcare Technology Development - Ministry of National Guard Health
    Affairs in Saudi Arabia, he works closely with multidisciplinary team of healthcare providers to address
    the workflow challenges, and transform it to an improvement opportunity, towards digitizing and
    automating the processes to an innovative business model.
    Currently; he is MBA candidate in Prince Mohammed Bin Salman College for Business &amp;
    Entrepreneurship, he has a bachelor degree in Biomedical Technology with more than 13 years’
    experience in Healthcare IT solutions, He is certified with several credentials such as CPHIMS, PMP, and
    Lean Six Sigma Black Belt.`,
    ],
  },
  {
    name: "Mohammed Al Tamimi",
    company: "Rubrik",
    title: "Regional Director",
    image: "mohammed.jpg",
    companyImage: undefined,
    text: [
      `Mohammed Al Tamimi is the Regional Director and responsible for the Go-To-Market
    operation in the Middle East and Africa region. 
    In his 20 years of career his core responsibilities included diverse roles spanning sales,
    channel and operations with major technology vendors globally. Prior to Rubrik, he was with
    Palo Alto Networks in the role of Regional Sales Manager covering the Gulf region, prior to
    that was at Commvault as Regional Sales and Channel Manager for MENAT
    region.  Mohammed is a recognized technology leader known for his strategic approach on
    how to achieve business goals by leveraging a balanced strategic relations between
    partners and customers and is seen at IT related events and conferences as a key speaker.`,
    ],
  },
  {
    name: "Marwan Abdulaziz Janahi",
    company: "Dubai Science Park",
    title: "Managing Director",
    image: "marwan.jpg",
    companyImage: undefined,
    text: [``],
  },
];

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href='/' onClick={(e) => updateModal(e, speaker)}>
        <Card
          className='card-background-image mx-auto p-3 rounded-0'
          style={{
            borderRadius: 0,
            boxShadow: "none",
            // background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className='px-0'>
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  alt='speaker'
                  width='100%'
                  className='rounded-0'
                />
              </Col>

              <Col xs={12} className='px-0'>
                <div className='text-center'>
                  <p
                    className='text-700  mt-2 mb-0 d-block px-0'
                    style={{ fontSize: "14px" }}
                  >
                    {speaker.name}
                  </p>
                  <p className='text-300 m-0' style={{ fontSize: "14px" }}>
                    {speaker.title}
                  </p>
                  <p
                    className='text-400 m-0 d-block px-0'
                    style={{ fontSize: "14px" }}
                  >
                    {speaker.company}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className='section'
        data-parallax={true}
      >
        <div className='filter' />
        <Container>
          <Row>
            <Col lg={12} className=''>
              <h1 className='text-dark text-center section-title'>
                <b>PAST SPEAKERS</b>
              </h1>
            </Col>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className='ml-auto mr-auto px-1' lg={3} md={6} key={index}>
                  <ScrollAnimation
                    animateIn='fadeInUp'
                    animateOnce={true}
                    duration={0.5}
                  >
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SpeakerGrid;
